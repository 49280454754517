import { Fragment, FunctionalComponent, h } from '@stencil/core';
import { deepLinkInApp } from '../../../global/route-in-app';
import { openInNewWindow } from '../../../global/browser';
import { Timestamp } from 'firebase/firestore';
import { translate } from '../../../global/translate';
import { formatDateFromFirestoreTimestamp } from '../../../services/firestore/utils';

export const OgChatBubble: FunctionalComponent<{
  avatarUrl?: string;
  breakWords: boolean;
  key?: string;
  message: string;
  myMessage: boolean;
  readAt?: Timestamp;
  showTimestamp: boolean;
  timestamp?: Timestamp;
}> = ({
  avatarUrl,
  breakWords,
  key,
  message,
  myMessage,
  readAt,
  showTimestamp,
  timestamp,
}) => (
  <div
    key={key}
    class={{
      'chat-bubble-wrapper': true,
      'my-message': myMessage,
      'break-word-hyphens': breakWords,
    }}
  >
    {avatarUrl && (
      <ion-avatar>
        <img src={avatarUrl} />
      </ion-avatar>
    )}
    <div
      class={{
        'chat-bubble': true,
        'my-message': myMessage,
      }}
    >
      <div>{renderMessage(message)}</div>
      {showTimestamp && (
        <p class="ion-no-margin sent-read">
          <small>
            {timestamp ? (
              translate('SENT_AT', {
                date: formatDateFromFirestoreTimestamp(timestamp),
              })
            ) : (
              <ion-skeleton-text />
            )}
          </small>
        </p>
      )}
      {readAt && (
        <p class="ion-no-margin sent-read">
          <small>
            <ion-text color="primary" class="ion-margin-right">
              {/* admin only - no need to translate */}
              Read {formatDateFromFirestoreTimestamp(readAt)}
            </ion-text>
          </small>
        </p>
      )}
    </div>
  </div>
);

async function routeToUrl(url: string) {
  const routedInApp = await deepLinkInApp(url);
  if (routedInApp) return;
  // external link - open via browser
  openInNewWindow({ url });
}

function renderMessage(message?: string) {
  const links = message?.match(/\bhttps?:\/\/\S+/gi);
  const messageWithBreaks = message?.split('\n').map(p => <p>{p}</p>);
  if (!links || links.length < 1) return messageWithBreaks;
  return (
    <Fragment>
      {messageWithBreaks}
      {links.map(url => (
        <Fragment>
          <br />
          <a href="#" onClick={() => routeToUrl(url)}>
            {url}
          </a>
        </Fragment>
      ))}
    </Fragment>
  );
}
